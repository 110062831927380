<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div>
          <div class="input-group">
            <v-select
              v-model="field.value"
              class="form-control"
              :class="validationState"
              multiple
              :disabled="isDisabled"
              :items="selectOptions"
              item-text="label"
              item-value="value"
              :no-data-text="$t('workflowDesigner.noSelectData')"
              :menu-props="{ offsetY: true }"
              @input="selectChange"
            >
              <template #selection="{ item, index }">
                <span v-if="index === 0">{{ item.label }}</span>
                <span v-if="index === 1">, {{ item.label }}</span>
                <span v-if="index === 2" class="grey--text text-caption ml-2">
                  {{
                    $t("general.selectionOther", {
                      count: field.value.length - 2
                    })
                  }}
                </span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  props: {
    field: {
      type: Object
    },
    configValues: {
      type: Array
    },
    outputValues: {
      type: Array,
      default: () => []
    },
    debugValues: {
      type: Array,
      default: () => []
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    node: {
      type: Object,
      default: () => {}
    },
    conditionValue: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      configValue: null,
      valueIndex: null,
      debugValue: null,
      selectedConfigValue: undefined,
      newCondition: "",
      disableSecondField: ["isNull", "isNotNull", "isEmpty", "isNotEmpty"],
      showConditions: true,
      tabSize: 4,
      jsonData: ""
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    validationState: function () {
      if (
        this.conditionValue &&
        !this.isDisabled &&
        !this.value &&
        this.areaInvalid
      ) {
        return "is-invalid";
      }

      if (!this.field.required || (!this.edited && !this.areaInvalid)) {
        return "";
      }
      if (!this.value) {
        return "is-invalid";
      }
      return "is-valid";
    },
    type: function () {
      if (this.field.type === "int") {
        return "number";
      } else if (
        this.field.type === "string" ||
        this.field.type === "textarea" ||
        this.field.type === "password"
      ) {
        return "text";
      }
      return "";
    },
    selectOptions: function () {
      if (!this.field.options) {
        return [];
      }

      let options = this.field.options.map(o => {
        if (typeof o === "string") {
          o = { value: o, label: o, group: null };
        }
        let label = o.value;
        if (o.label) {
          label = o.label;
        } else if (this.$te(`${this.field.option}Options.${o.value}`)) {
          label = this.$t(`${this.field.option}Options.${o.value}`);
        }

        return {
          value: o.value,
          label: label,
          group: o.group ?? null
        };
      });

      let optionsSorted = options;
      if (this.field.sort === "desc" || this.field.sort === -1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return b.group.localeCompare(a.group);
          }
          return b.label.localeCompare(a.label);
        });
      } else if (this.field.sort === "asc" || this.field.sort === 1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return a.group.localeCompare(b.group);
          }
          return a.label.localeCompare(b.label);
        });
      }

      let newOptions = [];
      let lastGroup = null;
      optionsSorted.forEach(option => {
        if (option.group !== null && lastGroup !== option.group) {
          newOptions.push({
            header: option.group
          });
          lastGroup = option.group;
        }
        newOptions.push(option);
      });
      return newOptions;
    }
  },
  watch: {
    value: function () {
      this.edited = true;

      this.$set(this.field, "value", this.value);
      this.$emit("change", this.value);

      if (this.field.name === "error_handling") {
        bus.$emit("show-hide-critical-path");
      } else if (this.field.name === "functions") {
        if (this.field.options === undefined || this.field.options === null) {
          return;
        }
        const option = this.field.options.find(o => o.value === this.value);
        if (option && option.types) {
          this.node.attrs.data.input[0].value = JSON.stringify(
            option.types,
            null,
            1
          );
        }
      }
    }
  },
  mounted() {
    this.value = this.field.value ?? [];
  },
  methods: {
    selectChange(value) {
      this.value = value;
      if (this.field.onChange !== undefined) {
        bus.$emit(
          "fireAction",
          {
            name: this.field.onChange,
            label: this.field.onChange
          },
          false
        );
      }

      setTimeout(this.checkRequiredFields, 100);
    },
    checkRequiredFields() {
      bus.$emit("checkRequiredFields");
    }
  }
};
</script>

<style lang="scss">
.modal-body.add-config-value {
  padding: 0;
}

.input-group-append {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}

.v-input--selection-controls__input .v-icon {
  color: #e4ebef;
}

.modal-xl.create-data-structure {
  max-width: 90%;
}
</style>
